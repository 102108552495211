import React from "react";
import { Box, Container, useBreakpointValue } from "@chakra-ui/react";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
/*
const menuData = [
  {
    category: "primary",
    text: "Our Counters",
    items: [
      { text: "Instagram", url: "/en/counter/instagram/5D" },
      { text: "Facebook" },
      { text: "Tiktok" },
      { text: "Custom" },
    ],
  },
  {
    category: "primary",
    text: "Happy Customers",
    items: [
      { text: "Restaurants" },
      { text: "Shops" },
      { text: "Agencies" },
      { text: "VIP" },
    ],
  },
  {
    category: "primary",
    text: "Design System",
    url: "/design-system",
  },
  {
    category: "secondary",
    text: "Login",
  },
  {
    category: "secondary",
    text: "Support",
  },
  {
    category: "secondary",
    text: "Shop Now",
    variant: "outline",
  },
];*/

export const Navbar = ({ isAbsolute = false, menuData = [] }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Box
      as="nav"
      role="navigation"
      alignContent="center"
      position={isAbsolute ? "absolute" : "relative"}
      width={"100%"}
      paddingY={1}
      zIndex={100}
    >
      <Container>
        {isDesktop ? (
          <Desktop menuData={menuData} />
        ) : (
          <Mobile menuData={menuData} />
        )}
      </Container>
    </Box>
  );
};
