import { useTheme, useColorMode } from "@chakra-ui/react";

export const useGradient = (gradientName = "default.default") => {
  let [scheme, ...name] = gradientName.split(".");

  const theme = useTheme();
  const { colorMode } = useColorMode();

  if (theme?.gradients?.[scheme] === undefined) {
    scheme = "default";
    name = "default";
  }

  let colorValues = theme?.gradients?.[scheme];
  if (colorMode === "dark" && theme.gradients?.[scheme]?._dark !== undefined) {
    colorValues = theme.gradients?.[scheme]?._dark;
  }

  const resolvedGradient = name.reduce((acc, key) => acc[key], colorValues);
  if (resolvedGradient === undefined) {
    return colorValues.default;
  }
  return resolvedGradient;
};
