import React from "react";
import he from "he";
import { Link } from "gatsby";
import {
  AspectRatio,
  Box,
  Heading,
  LinkBox,
  Stack,
  Text,
  LinkOverlay,
} from "@chakra-ui/react";
import { TwicPicture } from "@twicpics/components/react";

export const ArticlePreview = (props) => {
  const { post } = props;
  let image = "";
  if (post.Page?.Image?.url) {
    image = post.Page?.Image?.url.replace(
      "https://smiirl-media.s3.eu-west-1.amazonaws.com/",
      "media/"
    );
  } else if (post.FeaturedImage) {
    image = post.FeaturedImage.replace(
      "https://cdn.smiirl.com/blog/",
      "media/blog/"
    );
  }
  return (
    <LinkBox as="article" rounded="md" marginBottom={{ base: 36, md: 24 }}>
      <Stack
        spacing={{ base: 3, md: 6 }}
        direction={{ base: "column", md: "row" }}
      >
        <Box width={{ base: "100%", md: "33%" }}>
          <AspectRatio
            ratio={{ base: 1, md: 1 }}
            p={0}
            sx={{
              ".twic-i": {
                width: "100%",
                height: "auto",
                rounded: "md",
              },
            }}
          >
            <TwicPicture
              src={image}
              ratio="1, @md 1"
              sizes="100vw, @md 350px"
            />
          </AspectRatio>
        </Box>
        <Stack width={{ base: "100%", md: "66%" }} overflow={"hidden"}>
          <Stack>
            <Text fontSize="sm">
              <strong>{post.Category}</strong>, {post.Date}
            </Text>
            <Heading as="h2" size="xs" py={1} fontWeight={550}>
              <LinkOverlay as={Link} to={`/${post.locale}/blog/${post.slug}`}>
                {he.decode(post.Title)}
              </LinkOverlay>
            </Heading>
          </Stack>
          <Text>{he.decode(post?.Excerpt?.data?.Excerpt)}</Text>
        </Stack>
      </Stack>
    </LinkBox>
  );
};
