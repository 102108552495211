import React, { useCallback, useState } from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { Box } from "@chakra-ui/react";
import { TwicPicture } from "@twicpics/components/react";

export const Lottie = ({
  src = "",
  fallback = "",
  loop = true,
  autoplay = true,
  behavior = undefined,
  ...props
}) => {
  const [dotLottie, setDotLottie] = useState(null);
  const [lottieLoaded, setLottieLoaded] = useState(false);

  React.useEffect(() => {
    const onLoad = () => {
      setLottieLoaded(true);
    };

    const onLoadError = () => {
      setLottieLoaded(false);
    };

    if (dotLottie) {
      dotLottie.addEventListener("load", onLoad);
      dotLottie.addEventListener("loadError", onLoadError);
    }

    return () => {
      if (dotLottie) {
        dotLottie.removeEventListener("load", onLoad);
        dotLottie.removeEventListener("loadError", onLoadError);
      }
    };
  }, [dotLottie]);

  const dotLottieRefCallback = (dotLottie) => {
    setDotLottie(dotLottie);
  };

  const onClick = useCallback(() => {
    if (dotLottie && behavior === "restart-on-click") {
      dotLottie.setFrame(0);
    }
  }, [dotLottie, behavior]);

  return (
    <Box
      overflow="hidden"
      {...props}
      cursor={behavior !== undefined ? "pointer" : "default"}
      sx={{ ".twic-p>img": { width: props.width, height: props.height } }}
    >
      {fallback && !lottieLoaded && (
        <TwicPicture
          src={fallback}
          ratio="none"
          mode="cover"
          placeholder="preview"
        />
      )}

      <DotLottieReact
        onClick={onClick}
        src={src}
        loop={loop}
        autoplay={autoplay}
        dotLottieRefCallback={dotLottieRefCallback}
      />
    </Box>
  );
};
