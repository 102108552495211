import React from "react";
import { Flex } from "@chakra-ui/react";
import { Navbar } from "./navbar";
import { Footer } from "./Footer";
import { useLayoutContext } from "context/LayoutContext";

const parseMetadata = (entry, level = 0) => {
  if (level > 10 || entry === undefined) {
    return;
  }
  if (entry.metadata !== undefined && typeof entry.metadata === "string") {
    try {
      entry.metadata = JSON.parse(entry.metadata);
    } catch (e) {
      entry.metadata = {};
    }
  }
  if (Array.isArray(entry.nodes)) {
    entry.nodes.forEach((node) => {
      parseMetadata(node, level + 1);
    });
  }
  if (Array.isArray(entry.entries)) {
    entry.entries.forEach((node) => {
      parseMetadata(node, level + 1);
    });
  }
  if (entry.menu !== undefined) {
    parseMetadata(entry.menu, level + 1);
  }
};

export const Layout = ({ children, pageContext, data = {} }) => {
  const { updateLayout, layout } = useLayoutContext();
  const { allStrapiLayout } = data;
  let metadata = {};
  try {
    metadata = JSON.parse(pageContext?.Metadata);
  } catch (e) {}

  if (pageContext?.Metadata);

  parseMetadata(allStrapiLayout);

  const layoutName =
    layout?.name === "default"
      ? pageContext?.layout?.name ?? "default"
      : layout?.name ?? "default";

  React.useEffect(() => {
    if (pageContext?.layout) {
      updateLayout(pageContext?.layout);
    }
  }, [pageContext?.layout, updateLayout]);

  if (layoutName === "none") {
    return (
      <Flex as="main" role="main" direction="column" flex="1">
        {children}
      </Flex>
    );
  }

  return (
    <Flex direction="column" flex="1">
      <Navbar
        isAbsolute={metadata?.menuPosition === "absolute"}
        menuData={
          allStrapiLayout?.nodes?.find(
            (node) => node.identifier === "page.header"
          )?.entries
        }
      />
      <Flex as="main" role="main" direction="column" flex="1">
        {children}
      </Flex>
      <Footer
        menuData={
          allStrapiLayout?.nodes?.find(
            (node) => node.identifier === "page.footer"
          )?.entries
        }
      />
    </Flex>
  );
};
