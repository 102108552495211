import React, { useRef, useState } from "react";
import { ToggleGroup } from "components";
import { Box, Button, Flex } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";

export const Slideshow = ({ slidesContent, ...props }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const controlsAnimation = useAnimation();
  const slidesAnimation = useAnimation();
  const isDragging = useRef(false);
  const [slide, setSlide] = useState(0);

  const onClick = (index) => {
    if (containerRef.current && contentRef.current) {
      const item = contentRef?.current?.children[index];
      const itemLeft = item.offsetLeft ?? 0;
      const firstLeft = contentRef?.current?.children[0].offsetLeft ?? 0;
      const scrollTo = Math.max(
        0,
        Math.min(
          itemLeft -
            firstLeft -
            (containerRef.current.offsetWidth / 2 - item.offsetWidth / 2),
          contentRef.current.offsetWidth - containerRef.current.offsetWidth
        )
      );
      controlsAnimation.start({
        x: -scrollTo,
        transition: { type: "spring", stiffness: 300, damping: 30 },
      });
      slidesAnimation.start({
        x: -containerRef.current.offsetWidth * index,
        transition: { type: "tween" },
      });
      setSlide(index);
    }
  };

  return (
    <Box {...props}>
      <Box ref={containerRef}>
        <ToggleGroup
          as={motion.div}
          drag="x"
          dragConstraints={containerRef}
          whileTap={{ cursor: "grabbing" }}
          animate={controlsAnimation}
          ref={contentRef}
          onDragStart={() => (isDragging.current = true)}
          onDragEnd={() => setTimeout(() => (isDragging.current = false), 100)}
        >
          {slidesContent.map((item, index) => (
            <Button
              key={index}
              variant={"toggle"}
              onClick={() => !isDragging.current && onClick(index)}
              isActive={slide === index}
            >
              {item.Button}
            </Button>
          ))}
        </ToggleGroup>
      </Box>
      <Box marginTop={9}>
        <Flex width={`${slidesContent.length * 100}%`}>
          {slidesContent.map((item, index) => (
            <Box
              as={motion.div}
              animate={slidesAnimation}
              key={index}
              opacity={slide === index ? 1 : 0}
              width="100%"
            >
              {item.Slide}
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};
