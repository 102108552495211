import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import { Menu } from "./Menu";

export const MenuList = ({
  title = "Menu",
  variant = "default",
  items = [],
  ...props
}) => {
  return (
    <Stack spacing="4" minW={{ lg: "40" }}>
      <Text fontSize="sm" fontWeight="semibold" color="fg.accent.subtle">
        {title}
      </Text>
      <Stack spacing="3" shouldWrapChildren>
        {items.map((item, index) => (
          <Menu key={index} menuData={item} {...props} />
        ))}
      </Stack>
    </Stack>
  );
};
