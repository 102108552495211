export const colors = {
  smiirl: {
    white: "#FFFFFF",
    50: "rgba(0, 0, 0, 0.05)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    750: "rgba(0, 0, 0, 0.72)",
    800: "rgba(0, 0, 0, 0.80)",
    850: "rgba(0, 0, 0, 0.88)",
    900: "#000000",
    dark: "rgba(0, 0, 0, 0.898)",
    _hover: {},
  },
};

export const gradients = {
  default: {
    default: "linear(90deg, #FFFFFF 0%, #000000 100%)",
    _dark: {
      default: "linear(90deg, #000000 0%, #FFFFFF 100%)",
    },
  },
  smiirl: {
    default:
      "linear(110deg, #FFFFFF00 0%, #FAF7FF 33%, #FFE8ED 67%, #FFFFFF00 100%)",
    _dark: {
      default:
        "linear(110deg, #00000000 0%, #00001A 33%, #1A0000 67%, #00000000 100%)",
    },
  },
};

export const defaultColorScheme = "smiirl";
