import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  baseStyle: defineStyle({
    borderRadius: "full",
    fontWeight: "600",
    paddingY: 1,
  }),
  variants: {
    toggle: {
      color: "smiirl.700",
      rounded: "xl",
      fontSize: "0.96em",
      background: "transparent",
      paddingX: 5,
      height: "auto",
      paddingY: "0.65em",
      _hover: {
        background: "smiirl.100",
      },
      _focusVisible: {
        background: "smiirl.100",
      },
      _active: {
        color: "smiirl.900",
        background: "white",
        boxShadow: "md !important",
      },
    },
    solid: {
      color: "white",
      background: "smiirl.900",
      _hover: {
        color: "white",
        background: "smiirl.850",
      },
      _active: {
        color: "white",
        background: "smiirl.800",
      },
    },
    outline: {
      borderWidth: "1px",
      borderColor: "smiirl.900",
      color: "smiirl.900",
      _hover: {
        color: "white",
        background: "smiirl.850",
      },
      _active: {
        color: "white",
        background: "smiirl.900",
      },
    },
  },

  // The default size and variant values*/
  defaultProps: {
    size: "sm",
    variant: "solid",
    colorScheme: "smiirl",
  },
});
