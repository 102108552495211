import { defineStyleConfig } from "@chakra-ui/react";

export const Text = defineStyleConfig({
  variants: {
    hero: {
      fontWeight: 500,
      letterSpacing: "tight",
    },
    bigQuote: {
      fontSize: ["xl", null, "3xl"],
      fontWeight: 700,
      lineHeight: "shorter",
    },
    poem: {
      fontWeight: "medium",
      fontSize: ["2xl", null, "3xl"],
      lineHeight: "short",
    },
    focus: {
      fontSize: "lg",
      fontWeight: 550,
      letterSpacing: "-0.010em",
    },
  },

  // The default size and variant values*/
  defaultProps: {
    variant: "solid",
  },
});
