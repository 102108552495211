import React from "react";
import { Box } from "@chakra-ui/react";
import { useGradient } from "hooks/useGradient";

export const BackgroundGradient = ({
  gradient = "smiirl.default",
  ...props
}) => {
  const bgGradient = useGradient(gradient);
  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      width="100%"
      height="100%"
      transform="translate(-50%, -50%)"
      bgGradient={bgGradient}
      backgroundSize="100% 100%"
      backgroundRepeat="no-repeat"
      zIndex={-5}
      {...props}
    />
  );
};
