import React from "react";
import { useRadioGroup, HStack, SimpleGrid } from "@chakra-ui/react";
import { RadioCard } from "./RadioCard";

export const RadioCardGroup = ({
  options,
  name,
  isSticky,
  defaultValue,
  onChange,
  sameWidth = true,
  ...props
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  });

  const group = getRootProps();

  // If sameWidth is true, we use a SimpleGrid to display the RadioCard components
  let ComponentContainer = HStack;
  let componentProps = {};
  if (sameWidth) {
    ComponentContainer = SimpleGrid;
    componentProps = {
      columns: options.length,
    };
  }

  return (
    <ComponentContainer
      {...group}
      {...componentProps}
      padding="0.125rem"
      justifyContent="space-between"
      backgroundColor="#1A1A1A10"
      rounded="full"
      textAlign={"center"}
      gap={1}
      width={"fit-content"}
      {...props}
    >
      {options.map((option, index) => {
        const radio = getRadioProps({ value: option.value });
        return (
          <RadioCard key={index} {...radio}>
            {option.children}
          </RadioCard>
        );
      })}
    </ComponentContainer>
  );
};
