import React from "react";
import { Icon, IconButton } from "@chakra-ui/react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

export const MenuIcon = ({ isOpen = false, onClick = undefined }) => {
  return (
    <IconButton
      onClick={onClick}
      icon={<Icon as={isOpen ? AiOutlineClose : AiOutlineMenu} w={6} h={6} />}
      variant="link"
      aria-label="Menu"
      color={"black"}
    />
  );
};
