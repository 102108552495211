import React from "react";
import { graphql } from "gatsby";
import { Button, AccordionItem, AccordionButton } from "@chakra-ui/react";
import { Link } from "gatsby";

export const fragment = graphql`
  fragment MenuTo on STRAPI__COMPONENT_PAGES_MENU_TO {
    id
    text
    metadata
    page {
      id
      slug
    }
  }
`;

export const MenuTo = ({
  id,
  page,
  url,
  metadata,
  text,
  variant = "",
  onClickLeaf,
  ...props
}) => {
  const [variantRoot] = variant.split(".");

  const componentProps = {};
  if (page !== undefined && page !== null) {
    const slug = page.slug ?? "";
    componentProps.to = slug.startsWith("/") ? slug : `/${slug}`;
    componentProps.as = Link;
  } else if (url !== undefined) {
    componentProps.href = url;
    componentProps.as = "a";
  }
  if (variantRoot === "accordion") {
    return (
      <AccordionItem>
        <AccordionButton {...componentProps} onClick={onClickLeaf}>
          {text}
        </AccordionButton>
      </AccordionItem>
    );
  }
  return (
    <Button
      variant="text"
      justifyContent="start"
      {...componentProps}
      onClick={onClickLeaf}
    >
      {text}
    </Button>
  );
};
