import * as React from "react";
import CustomLayout from "components/layout/wrapPageElement";
import { LayoutProvider } from "context/LayoutContext";
import ReactDOM from "react-dom/client";

export const wrapPageElement = CustomLayout;

export const wrapRootElement = ({ element }) => {
  return <LayoutProvider>{element}</LayoutProvider>;
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
