import React, { useState } from "react";
import {
  Flex,
  Spacer,
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  useDisclosure,
  DrawerOverlay,
  Container,
  Accordion,
} from "@chakra-ui/react";
import { Logo } from "../Logo";
import { MenuIcon, CartIcon } from "../icons";
import { Link } from "gatsby";
import { Menu } from "../menu";

export const MobileDrawer = ({ mobileNavbar, menuData, ...props }) => {
  return (
    <Drawer placement="top" {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody paddingX={0} paddingTop={1} paddingBottom={4}>
          <Container>
            <Flex alignItems={"center"} height={12}>
              <Logo transform="translateY(-10%)" />
              <Spacer />
              <MenuIcon isOpen={true} onClick={mobileNavbar.onToggle} />
            </Flex>
            <Stack spacing="6" align="stretch">
              <Accordion defaultIndex={[0]} variant="menu">
                <Menu
                  menuData={menuData}
                  variant="accordion.default"
                  onClickLeaf={mobileNavbar.onClose}
                />
              </Accordion>
            </Stack>
          </Container>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export const Mobile = ({ menuData = [] }) => {
  const [count, setCount] = useState(18);
  const mobileNavbar = useDisclosure();
  return (
    <Flex alignItems={"center"} height={12}>
      <Logo transform="translateY(-10%)" />
      <Spacer />
      <CartIcon
        count={count}
        onClick={() => {
          setCount((c) => {
            if (c >= 19) {
              return 0;
            }
            return c + 1;
          });
        }}
      />
      <MenuIcon isOpen={mobileNavbar.isOpen} onClick={mobileNavbar.onToggle} />
      <MobileDrawer
        menuData={menuData}
        mobileNavbar={mobileNavbar}
        isOpen={mobileNavbar.isOpen}
        onClose={mobileNavbar.onClose}
      />
    </Flex>
  );
};
