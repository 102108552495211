import React, { forwardRef } from "react";
import { Box, Container as ChakraContainer } from "@chakra-ui/react";
import { BackgroundGradient } from "./BackgroundGradient";
import { BackgroundImage } from "./BackgroundImage";

export const Container = forwardRef((props, ref) => {
  const minHeightOptions = {
    none: "",
    default: "75vh",
    full: "100vh",
  };
  const variantOptions = {
    default: {
      container: {
        paddingY: { base: "16", md: "24" },
      },
      box: {},
    },
    full: { container: {}, box: {} },
  };
  const {
    children,
    minHeight = "default",
    backgroundColor = undefined,
    backgroundImage = null,
    backgroundGradient = null,
    variant = "default",
    ...otherProps
  } = props;

  const variantProps = variantOptions[variant] ?? variantOptions.default;

  return (
    <Box
      backgroundColor={backgroundColor}
      width="100%"
      maxWidth="100%"
      overflow="hidden"
      position="relative"
      {...variantProps.box}
    >
      {backgroundGradient !== null && (
        <BackgroundGradient gradient={backgroundGradient.gradient} />
      )}
      {backgroundImage !== null && (
        <BackgroundImage
          url={backgroundImage?.url}
          alt={backgroundImage?.alt}
        />
      )}
      <ChakraContainer
        minHeight={minHeightOptions[minHeight] ?? minHeight}
        alignContent="center"
        ref={ref}
        {...variantProps.container}
        {...otherProps}
      >
        {children}
      </ChakraContainer>
    </Box>
  );
});
