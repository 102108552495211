import React from "react";
import { useRadio, Box } from "@chakra-ui/react";

export const RadioCard = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label" width={"100%"} height={"100%"}>
      <input {...input} />
      <Box
        {...radio}
        fontSize="sm"
        cursor="pointer"
        outline="none"
        _checked={{
          bg: "white",
          color: "black",
        }}
        px={4}
        py={1}
        height={"100%"}
        rounded="full"
        alignContent={"center"}
        whiteSpace="nowrap"
        paddingTop={1}
        paddingBottom={1.5}
      >
        {props.children}
      </Box>
    </Box>
  );
};
