import * as React from "react";

const defaultState = {
  layout: "default",
  updateLayout: () => {},
};

const LayoutContext = React.createContext(defaultState);

export const useLayoutContext = () => React.useContext(LayoutContext);

export const LayoutProvider = ({ children }) => {
  const [state, setState] = React.useState({ name: "default" });

  const updateLayout = (updates) => {
    setState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  return (
    <LayoutContext.Provider value={{ layout: state, updateLayout }}>
      {children}
    </LayoutContext.Provider>
  );
};
