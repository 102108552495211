import { defineStyleConfig } from "@chakra-ui/react";

export const Heading = defineStyleConfig({
  variants: {
    hero: {
      fontWeight: 700,
      fontSize: ["4xl", null, "5xl"],
      lineHeight: "slight",
      letterSpacing: "tight",
    },
    section: {
      fontSize: "4xl",
      letterSpacing: "-0.05em",
      fontWeight: 600,
      textAlign: "center",
      whiteSpace: "pre-wrap",
      marginBottom: 9,
    },
    subSection: {
      fontSize: "3xl",
      color: "smiirl.600",
      letterSpacing: "-0.05em",
      fontWeight: 550,
      textAlign: "center",
      whiteSpace: "pre-wrap",
      marginBottom: 9,
    },
  },

  // The default size and variant values*/
  defaultProps: {
    variant: "solid",
  },
});
