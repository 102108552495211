import React from "react";
import { Container } from "components";
import {
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  SimpleGrid,
  Stack,
  Input,
  Text,
} from "@chakra-ui/react";
import { Logo } from "./Logo";
import { FaGithub, FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { Menu } from "./menu";

export const links = [
  {
    title: "Company",
    links: [
      { label: "Why Envelope", href: "#" },
      { label: "Our story", href: "#" },
      { label: "Careers", href: "#" },
      { label: "Press", href: "#" },
      { label: "FAQs", href: "#" },
    ],
  },
  {
    title: "Product",
    links: [
      { label: "How it works", href: "#" },
      { label: "Pricing", href: "#" },
      { label: "Use Cases", href: "#" },
      { label: "Integrations", href: "#" },
      { label: "SAML SSO", href: "#" },
    ],
  },
  {
    title: "Resources",
    links: [
      { label: "Blog", href: "#" },
      { label: "Partnerships", href: "#" },
      { label: "Case studies", href: "#" },
      { label: "Media Assets", href: "#" },
    ],
  },
  {
    title: "Legal",
    links: [
      { label: "Terms of Service", href: "#" },
      { label: "Privacy Policy", href: "#" },
      { label: "Offer terms", href: "#" },
      { label: "License", href: "#" },
    ],
  },
];

export const Footer = ({ menuData, ...props }) => {
  return (
    <Container backgroundGradient as="footer" role="contentinfo">
      <Stack
        justify="space-between"
        align="start"
        direction={"column"}
        spacing="8"
        width={"100%"}
      >
        <Stack spacing={{ base: "6", md: "8" }} align="start">
          <Logo />
          <Text color="fg.accent.muted">Much more than a number.</Text>
        </Stack>
        <Divider borderColor="#0000000A" />
        <SimpleGrid columns={{ base: 2, md: 4 }} gap="8" width={"full"}>
          <Menu menuData={menuData} variant="list" />
        </SimpleGrid>
        <Divider borderColor="#0000000A" />
        <SimpleGrid columns={{ base: 2, md: 4 }} gap="8" width={"full"}>
          <Stack spacing="4">
            <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
              Stay up to date
            </Text>
            <Stack spacing="4" direction={{ base: "column", sm: "row" }}>
              <Input placeholder="Enter your email" type="email" required />
              <Button type="submit" flexShrink={0}>
                Subscribe
              </Button>
            </Stack>
          </Stack>
          <ButtonGroup variant="tertiary.accent">
            <IconButton
              as="a"
              href="#"
              aria-label="LinkedIn"
              icon={<FaLinkedin />}
            />
            <IconButton
              as="a"
              href="#"
              aria-label="GitHub"
              icon={<FaGithub />}
            />
            <IconButton
              as="a"
              href="#"
              aria-label="Twitter"
              icon={<FaXTwitter />}
            />
          </ButtonGroup>
        </SimpleGrid>
      </Stack>
    </Container>
  );
};
