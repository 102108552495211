import { Accordion } from "./Accordion";
import { Button } from "./Button";
import { Container } from "./Container";
import { Heading } from "./Heading";
import { Text } from "./Text";

export const components = {
  Container,
  Button,
  Accordion,
  Heading,
  Text,
};
