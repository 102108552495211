import React from "react";
import {
  Stack,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { Menu } from "./Menu";

export const MenuAccordion = ({ title = "Menu", items = [], ...props }) => {
  return (
    <AccordionItem>
      <AccordionButton>
        {title}
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel paddingX={0}>
        <Stack direction="column" spacing={0} paddingLeft={0}>
          {items.map((item, index) => (
            <Menu key={index} menuData={item} {...props} />
          ))}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};
