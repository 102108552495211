import React from "react";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import { Menu } from "./Menu";

export const PopoverIcon = ({ isOpen = false }) => {
  const iconStyles = {
    transform: isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.2s",
    transformOrigin: "center",
  };
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />;
};

export const MenuPopover = ({
  title = "Menu",
  variant = "default",
  items = [],
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger="hover"
      openDelay={0}
      closeDelay={400}
      matchWidth={true}
      isLazy={true}
    >
      <PopoverTrigger>
        <Button rightIcon={<PopoverIcon isOpen={isOpen} />}>{title}</Button>
      </PopoverTrigger>
      <PopoverContent p="2" maxW="fit-content">
        <Stack spacing="0" alignItems="stretch">
          {items.map((item, index) => (
            <Menu key={index} variant={variant} menuData={item} {...props} />
          ))}
        </Stack>
      </PopoverContent>
    </Popover>
  );
};
