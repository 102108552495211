import { defineStyleConfig, defineStyle } from "@chakra-ui/react";

const menu = defineStyle(() => {
  return {
    container: {
      border: "0px none",
    },
    button: {
      textDecoration: "none",
      paddingY: 1,
      paddingX: 0,
      color: "smiirl.900",
      _hover: {
        color: "smiirl.800",
        bgColor: "transparent",
      },
      "_focus, _highlighted": {
        color: "smiirl.900",
        bgColor: "transparent",
      },
      _active: {
        color: "smiirl.900",
        bgColor: "transparent",
      },
    },
    panel: {
      paddingY: 0,
    },
  };
});

const variants = {
  menu,
};

export const Accordion = defineStyleConfig({ variants });
