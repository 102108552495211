import React from "react";
import { Lottie } from "components";
import { Box } from "@chakra-ui/react";

export const Logo = ({ ratio = 1, ...props }) => {
  return (
    <Box {...props} width={`${88 * ratio}px`} height={`${24 * ratio}px`}>
      <Lottie
        width={`${88 * ratio}px`}
        height={`${24 * ratio}px`}
        mb="3px"
        src={
          "https://cdn.smiirl.com/strapi-dev/smiirl_logo_animated_b4053433e6.json"
        }
        fallback="/media/strapi-dev/smiirl_logo_static_f375cc7542.png"
        loop
        autoplay
        behavior="restart-on-click"
      />
    </Box>
  );
};
