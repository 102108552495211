import React, { forwardRef } from "react";
import { ButtonGroup } from "@chakra-ui/react";
import { motion } from "framer-motion";

export const ToggleGroup = forwardRef((props, ref) => {
  return (
    <ButtonGroup
      as={motion.div}
      drag="x"
      whileTap={{ cursor: "grabbing" }}
      width="fit-content"
      cursor="grab"
      backgroundColor="#E8E8E8"
      padding="0.2em"
      rounded="2xl"
      overflowY="visible"
      spacing={3}
      zIndex={10}
      ref={ref}
      {...props}
    ></ButtonGroup>
  );
});
